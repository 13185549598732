import React, { useMemo } from "react"

import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Carousel from "../components/Carousel/Carousel"
import { CarouselItem } from "../components/Carousel/CarouselItem"

import { ButtonLink } from "../components/Button/Button"

import PreHeading from "../components/PreHeading"

import ImageAndText from "../components/ImageAndText/ImageAndText"
import CustomerSlider from "../components/CustomerSlider"
import Awards from "../components/Awards/Awards"
import SoloToOchestra from "../components/SoloToOrchestra/SoloToOrchestra.de"
import { PageType } from "../index"
import { SmoothScrollButton } from "../components/SmoothScrollButton"

import Style from "./index.module.css"
import LangAwareLink from "../components/LangAwareLink"

const IndexPage: PageType = ({ location }) => {
    const {
        aboutUsImage,
        aboutUsImageTablet,

        carouselCulture,
        carouselTraining,
        carouselSales,

        carouselCultureMobile,
        carouselTrainingMobile,
        carouselSalesMobile,

        productsCulture,
        productsTraining,
        productsSales,
    } = useStaticQuery(graphql`
        fragment ProductImage on File {
            childImageSharp {
                fixed(width: 256, height: 256, cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        query Images {
            aboutUsImage: file(relativePath: { eq: "office-meeting.jpg" }) {
                ...ImageAndTextSideImage
            }
            aboutUsImageTablet: file(relativePath: { eq: "office-meeting.jpg" }) {
                ...ImageAndTextSideImage_Tablet
            }

            carouselCulture: file(relativePath: { eq: "culture.jpg" }) {
                ...CarouselItemImage
            }
            carouselTraining: file(relativePath: { eq: "training.jpg" }) {
                ...CarouselItemImage
            }
            carouselSales: file(relativePath: { eq: "sales.jpg" }) {
                ...CarouselItemImage
            }
            
            carouselCultureMobile: file(relativePath: { eq: "culture.jpg" }) {
                ...CarouselItemImageMobile
            }
            carouselTrainingMobile: file(relativePath: { eq: "training.jpg" }) {
                ...CarouselItemImageMobile
            }
            carouselSalesMobile: file(relativePath: { eq: "sales.jpg" }) {
                ...CarouselItemImageMobile
            }

            productsCulture: file(relativePath: { eq: "culture.jpg" }) {
                ...ProductImage
            }
            productsTraining: file(relativePath: { eq: "training.jpg" }) {
                ...ProductImage
            }
            productsSales: file(relativePath: { eq: "sales.jpg" }) {
                ...ProductImage
            }
        }
    `)

    const carouselItems = useMemo(() => {
        if (
            !carouselCulture ||
            !carouselCultureMobile ||
            !carouselTraining ||
            !carouselTrainingMobile ||
            !carouselSales ||
            !carouselSalesMobile
        ) {
            return []
        }

        return [
            CarouselItem(
                "Quadio Culture",
                "Unternehmenskultur fördern",
                carouselCulture,
                carouselCultureMobile,
                (<>
                    <p className="text-white">Onboarden, ausrichten, durchstarten. Viele Persönlichkeiten und eine Vision. Das gelingt mit guter Führung, einer starken Unternehmenskultur und Quadio.</p>
                    <ButtonLink appearance="primary" to="/products/culture">Jetzt entdecken</ButtonLink>
                </>),
            ),
            CarouselItem(
                "Quadio Training",
                "Schulungswissen verankern",
                carouselTraining,
                carouselTrainingMobile,
                (<>
                    <p className="text-white">Ihren Return On Learning Invest steigern mit effektivem Wissensmanagement und dem Verfestigen von Wissen. Jederzeit und überall.</p>
                    <ButtonLink appearance="primary" to="/products/training">Jetzt entdecken</ButtonLink>
                </>)
            ),
            CarouselItem(
                "Quadio Sales",
                "Vertrieb schulen",
                carouselSales,
                carouselSalesMobile,
                (<>
                    <p className="text-white">Aus Vertrieblern Markenbotschafter machen, die erklärungsbedürftige Produkte auf den Punkt bringen und aus Kunden Fans machen, die besser wissen und erleben.</p>
                    <ButtonLink appearance="primary" to="/products/sales">Jetzt entdecken</ButtonLink>
                </>),
            ),
        ]
    }, [carouselCulture, carouselCultureMobile, carouselTraining, carouselTrainingMobile, carouselSales, carouselSalesMobile])

    return <Layout location={location}>
        <Seo
            title="Übersicht"
            description="Ihre digitalen Lösungen für smartes Wissensmanagement, starke Unternehmenskultur, effektive Onboardings und zielgerichtetes Branding."
        />
        <section className="section-x-margin">
            <div className="flex flex-wrap mobile:px-8 desktop:flex-no-wrap px-12 desktop:px-40">
                <div className="w-full desktop:w-3/5">
                    <h2 className="mobile:h3 sm_desktop:h2 desktop:h1">
                        Digital.<br />
                        Unternehmerisch.<br />
                        Empathisch.<br />
                    </h2>
                </div>
                <div className="w-full desktop:w-2/5 mt-0">
                    <p className="mb-4">
                        Ihre digitalen Lösungen für smartes Wissensmanagement, starke Unternehmenskultur, effektive Onboardings und zielgerichtetes Branding.
                    </p>
                    <SmoothScrollButton
                        appearance="primary"
                        href="#products"
                    >
                        Jetzt entdecken
                    </SmoothScrollButton>
                </div>
            </div>
        </section>
        <div id="products" className="inline-block w-full -mt-12" />
        <section className="mt-12">
            <Carousel className="mb-12 px-12 mobile:px-4 pb-0">
                {carouselItems}
            </Carousel>

            <div className="flex flex-no-wrap mobile:flex-wrap mobile:text-center justify-center sm_desktop:px-12 sm_desktop:justify-between desktop:justify-around px-12 desktop:px-40 section-x-margin">
                <div className="mobile:w-full w-1/3 px-4 m-auto mb-8 mobile:mb-12 sm_desktop:px-0 sm_desktop:m-0 sm_desktop:w-auto desktop:mb-0">
                    <span className="block tablet:h3 desktop:h1">+ 40.000</span>
                    <span className="text-sm text-blue-ink uppercase">Nutzer</span>
                </div>
                <div className="mobile:w-full w-1/3 px-4 m-auto mb-8 mobile:mb-12 sm_desktop:px-0 sm_desktop:m-0 sm_desktop:w-auto desktop:mb-0">
                    <span className="block tablet:h3 desktop:h1">+ 1,3 Mio.</span>
                    <span className="text-sm text-blue-ink uppercase">Erreichte Menschen</span>
                </div>
                <div className="mobile:w-full w-1/3 px-4 m-auto mb-8 mobile:mb-12 sm_desktop:px-0 sm_desktop:m-0 sm_desktop:w-auto desktop:mb-0">
                    <span className="block tablet:h3 desktop:h1">+ 18.000</span>
                    <span className="text-sm text-blue-ink uppercase">Standorte</span>
                </div>
            </div>
        </section>
        <SoloToOchestra />
        <section className="section-x-margin">
            <div className="hidden" aria-hidden={false}>
                Quadio Kunden
            </div>

            <CustomerSlider />
        </section>
        <section>
            <ImageAndText
                image={aboutUsImage}
                tabletImage={aboutUsImageTablet}
                fancy
            >
                <PreHeading>
                    Über uns
                </PreHeading>
                <h2>Unsere Mission: Menschen befähigen</h2>
                <p>Unsere Produkte sind darauf abgestimmt, Ihren unternehmerischen Erfolg sicherzustellen. Und da jeder Ihrer Mitarbeiter einen Einfluss darauf hat, ist es unsere Passion, sie zu befähigen und zu stärken. Wir haben herausgefunden, wie das funktioniert und wissen: Nur wer Menschen erreicht, kann etwas verändern. Wir richten unser Produkt nach den Menschen aus, die es nutzen.</p>
                <ButtonLink appearance="secondary" to="/about-us">Über uns</ButtonLink>
            </ImageAndText>
        </section>
        <section className="section-x-margin">
            <div className={Style.products_section_heading}>
                <PreHeading inline>
                    Bereiche
                </PreHeading>
                <h2 className="mobile:h3">Wie können wir Ihnen helfen?</h2>
            </div>
            <div className={Style.products_wrap}>
                <div className={Style.product}>
                    <div className={Style.product_image}>
                        <LangAwareLink aria-hidden className="block" to="/products/culture">
                            <Img className="w-full" fixed={productsCulture.childImageSharp.fixed} />
                        </LangAwareLink>
                    </div>
                    <div className={Style.product_content}>
                        <h3 className={Style.product_heading}>Unternehmenskultur</h3>
                        <p className={Style.product_content_text}>Onboarden, ausrichten, durchstarten. Viele Persönlichkeiten und eine Vision. Das gelingt mit guter Führung, einer starken Unternehmens- kultur und Quadio.</p>
                        <div className={Style.product_actions}>
                            <ButtonLink appearance="primary" to="/products/culture">Quadio Culture</ButtonLink>
                        </div>
                    </div>
                </div>
                <div className={Style.product}>
                    <div className={Style.product_image}>
                        <LangAwareLink aria-hidden className="block" to="/products/training">
                            <Img className="w-full" fixed={productsTraining.childImageSharp.fixed} />
                        </LangAwareLink>
                    </div>
                    <div className={Style.product_content}>
                        <h3 className={Style.product_heading}>Schulungswissen</h3>
                        <p className={Style.product_content_text}>Ihren Return On Learning Invest steigern mit effektivem Wissensmanagement und dem Verfestigen von Wissen. Jederzeit und überall.</p>
                        <div className={Style.product_actions}>
                            <ButtonLink appearance="primary" to="/products/training">Quadio Training</ButtonLink>
                        </div>
                    </div>
                </div>
                <div className={Style.product}>
                    <div className={Style.product_image}>
                        <LangAwareLink aria-hidden className="block" to="/products/sales">
                            <Img className="w-full" fixed={productsSales.childImageSharp.fixed} />
                        </LangAwareLink>
                    </div>
                    <div className={Style.product_content}>
                        <h3 className={Style.product_heading}>Marketing & Vertrieb</h3>
                        <p className={Style.product_content_text}>Aus Vertrieblern Markenbotschafter machen, die erklärungsbedürftige Produkte auf den Punkt bringen und aus Kunden Fans machen, die besser wissen und erleben.</p>
                        <div className={Style.product_actions}>
                            <ButtonLink appearance="primary" to="/products/sales">Quadio Sales</ButtonLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Style.products_section_awards}>
                <span hidden>Awards</span>
                <Awards />
            </div>
        </section>
    </Layout>
}

export default IndexPage
